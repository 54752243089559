import React, { useEffect, useReducer, useContext, useState } from "react";
import { Store } from "../../Store";
import { locationReducer as reducer } from "../../reducers/locationiReducer";
import {
  availableCarReducer,
  checkAvailabilityReducer,
  viewCarReducer,
} from "../../reducers/carReducer";
import { format } from "date-fns";
import { createBookingReducer } from "../../reducers/bookingReducer";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Form, Button, Container, Col } from "react-bootstrap";
import LoadingBox from "../layout/LoadingBox";
import axiosInstance from "../../utils/axiosUtil";
import { AiFillCar, AiFillSetting } from "react-icons/ai";
import { IoSpeedometerSharp } from "react-icons/io5";

export default function CreateBooking(props) {
  const { state } = useContext(Store);
  const { token } = state;
  const { id } = useParams();

  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });

  const [{ availableLoading }, dispatch1] = useReducer(availableCarReducer, {
    loading: true,
    error: "",
  });

  const [{ error }, dispatch2] = useReducer(checkAvailabilityReducer, {
    loading: true,
    error: "",
  });

  const [{ car }, dispatch3] = useReducer(viewCarReducer, {
    loading: true,
    error: "",
  });

  const [{ createloading }, dispatch4] = useReducer(createBookingReducer, {
    loading: true,
    error: "",
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [fromAddress, setFromAddress] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [availableCar, setAvailableCar] = useState([]);
  const [pickupLocations, setPickupLocations] = useState([]);
  const [dropOffLocations, setDropOffLocations] = useState([]);
  const [persons, setPersons] = useState("1 Person");
  const [lugguage, setLugguage] = useState("1 Luggage");
  const [insurance, setInsurance] = useState(false);
  const [number, setNumber] = useState(0);
  const [bookedDays, setBookedDays] = useState(0);
  const [name,setName]  = useState('')
  const [email,setEmail] = useState('')
 const [phone,setPhone] = useState('')
  const resetForm = () => {
    setStartDate("");
    setEndDate("");
    setFromAddress("");
    setToAddress("");
    setStartTime("");
    setEndTime("");
    setAvailableCar([]);
    setPickupLocations([]);
    setDropOffLocations([]);
    setPersons("1 Person");
    setLugguage("1 Luggage");
    setInsurance(false);
    setNumber(0);
    setName('');
    setEmail('');
    setPhone(0)
  };

  const reset = () => {
    props.onHide();
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    setAvailableCar([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });

        const { data } = await axiosInstance.get(
          `/api/location/get-locations`,
          {
            headers: { Authorization: token },
          }
        );
        setPickupLocations(data?.pickupLocations);
        setDropOffLocations(data?.dropOffLocations);
        dispatch({ type: "FETCH_SUCCESS" });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: "Server error!",
        });
        toast.error("Server error. Please try again later.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    };
    fetchData();
  }, [id, props.show, token]);

  const submitAvailableHandler = async (e) => {
    e.preventDefault();

    try {
      dispatch1({ type: "FETCH_REQUEST" });

      const { data } = await axiosInstance.post(
        `/api/car/getAvail-car`,
        {
          startDate,
          endDate,
          startTime,
          endTime,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (data) {
        dispatch1({ type: "FETCH_SUCCESS" });
        setAvailableCar(data?.availableCars);
      }
    } catch (err) {
      dispatch1({ type: "FETCH_FAIL" });
      toast.error("End Time Must Be Greater than Start Time", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const checkAvailableHandler = async (carId) => {
    try {
      dispatch2({ type: "FETCH_REQUEST" });

      const { data } = await axiosInstance.post(
        `/api/booking/check-availability`,
        {
          startDate,
          endDate,
          startTime,
          endTime,
          carId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (data.success === true) {
        dispatch2({ type: "FETCH_SUCCESS" });
        toast.success(data?.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setNumber(1);
      } else {
        toast.error(error, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (err) {
      dispatch2({ type: "FETCH_FAIL" });
      toast.error("Server error. Please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const selectedCarDetails = async (carId) => {
    try {
      dispatch3({ type: "FETCH_REQUEST" });

      const { data } = await axiosInstance.get(`/api/admin/findCar/${carId}`, {
        headers: { Authorization: token },
      });

      dispatch3({ type: "FETCH_SUCCESS", payload: data });
    } catch (err) {
      dispatch3({
        type: "FETCH_FAIL",
        payload: err,
      });
      toast.error("Server error. Please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const createBookingHandler = async (e) => {
    e.preventDefault();

    try {
      dispatch4({ type: "CREATE_REQUEST" });

      const { data } = await axiosInstance.post(
        `/api/admin/create-admin-booking`,
        {
          carId: car?._id,
          userId: state.userInfo._id,
          fromAddress: fromAddress,
          startDate: startDate,
          startTime: startTime,
          toAddress: toAddress,
          endDate: endDate,
          endTime: endTime,
          person: persons,
          luggage: lugguage,
          insurance: insurance,
          price: car?.price,
          discount: car?.discount,
          name:name,
          email:email,
          phone:phone

        },
        {
          headers: { Authorization: token },
        }
      );

      if (data) {
        dispatch4({ type: "CREATE_SUCCESS" });
        toast.success(data?.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setTimeout(() => {
          props.onHide();
          resetForm();
          window.location.reload();
        }, 3000);
      }
    } catch (err) {
      dispatch4({
        type: "CREATE_FAIL",
        payload: err,
      });
      toast.error("Server error. Please try again later.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const doSomething = async (id) => {
    await checkAvailableHandler(id);
    await selectedCarDetails(id);
  };

  const daysDifference = () => {
    const dateTimeDiff =
      new Date(`${format(new Date(endDate), "MMMM dd, yyyy")}, ${endTime}`) -
      new Date(`${format(new Date(startDate), "MMMM dd, yyyy")}, ${startTime}`);
    setBookedDays(Math.ceil(dateTimeDiff / (1000 * 60 * 60 * 24)));
  };

  useEffect(() => {
    if (startDate && endDate && startTime && endTime) {
      daysDifference();
    }
  }, [startDate, endDate, startTime, endTime]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {number === 0 && (
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Check Available Car
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={submitAvailableHandler}>
            <Modal.Body>
              <Container className="small-container">
                <Form.Group className="mb-3" controlId="firstname">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                    type="date"
                    min={new Date().toJSON().slice(0, 10)}
                    placeholder="Journey date"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="mobile_no">
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    value={startTime}
                    type="time"
                    onChange={(e) => setStartTime(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="mobile_no">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    value={endDate}
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                    min={new Date().toJSON().slice(0, 10)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="mobile_no">
                  <Form.Label>End Time</Form.Label>
                  <Form.Control
                    value={endTime}
                    type="time"
                    onChange={(e) => setEndTime(e.target.value)}
                    required
                  />
                </Form.Group>
                <Col className="col1">
                  {{ availableCar } &&
                    availableCar.length > 0 &&
                    availableCar.map((car) => (
                      <div key={car?._id} className="car__item">
                        <img src={car?.images[0]} alt="pic" className="w-100" />
                        <div className="car__item-content">
                          <h4 className="section__title">{car?.name}</h4>
                          <h6 className="rent__price">
                            <span className="car-price">
                              ${car?.price?.toFixed(2)}
                            </span>
                            <span>/day</span>
                          </h6>

                          <div className="car__item-info">
                            <div className="car-item-btm">
                              <span>
                                <AiFillCar />
                              </span>
                              <span className="span_m">{car?.model}</span>
                            </div>
                            <div className="car-item-btm">
                              <span>
                                <AiFillSetting />
                              </span>
                              <span className="span_m">{car?.automatic}</span>
                            </div>
                            <div className="car-item-btm">
                              <span>
                                <IoSpeedometerSharp />
                              </span>
                              <span className="span_m">{car?.speed}</span>
                            </div>
                          </div>
                          <Button
                            onClick={() => {
                              doSomething(car?._id);
                            }}
                          >
                            Book Now
                          </Button>
                        </div>
                      </div>
                    ))}
                </Col>
                <ToastContainer />
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => reset()}>
                Close
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={
                  !startTime ||
                  !endTime ||
                  !startDate ||
                  !endDate ||
                  availableLoading
                    ? true
                    : false
                }
              >
                Submit
              </Button>
              {availableLoading && <LoadingBox></LoadingBox>}
            </Modal.Footer>
          </Form>
        </>
      )}
      {number === 1 && (
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {car?.name} - Book The Car
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={createBookingHandler}>
            <Modal.Body>
              <Container className="small-container">
              <Form.Group className="mb-3" controlId="firstname">
                  <Form.Label>User's Name</Form.Label>
                  <Form.Control
                    value={name}
                    required
                    type="text"
                    placeholder="Enter user name"
                    onChange={(e)=>setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstname">
                  <Form.Label>User's Email</Form.Label>
                  <Form.Control
                    value={email}
                    required
                    type="text"
                    placeholder="Enter user email"
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="firstname">
                  <Form.Label>User's Phone No.</Form.Label>
                  <Form.Control
                    value={phone}
                    
                    type="text"
                    placeholder="Enter user phone no."
                    onChange={(e)=>setPhone(e.target.value)}
                  />
                </Form.Group>


                <Form.Group className="mb-3" controlId="firstname">

                  <Form.Label>From address</Form.Label>
                  <Form.Select
                    value={fromAddress}
                    onChange={(e) => {
                      setFromAddress(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select</option>
                    {pickupLocations?.map((pickUp) => (
                      <option key={pickUp} value={pickUp}>
                        {pickUp}
                      </option>
                    ))}
                    {pickupLocations?.length <= 0 && (
                      <option value="" disabled>
                        Locations will be added soon!
                      </option>
                    )}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstname">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    value={startDate}
                    required
                    type="date"
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="mobile_no">
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    value={startTime}
                    type="time"
                    readOnly
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="firstname">
                  <Form.Label>To address</Form.Label>
                  <Form.Select
                    value={toAddress}
                    onChange={(e) => {
                      setToAddress(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select</option>
                    {dropOffLocations?.map((dropOf) => (
                      <option key={dropOf} value={dropOf}>
                        {dropOf}
                      </option>
                    ))}
                    {dropOffLocations?.length <= 0 && (
                      <option value="" disabled>
                        Locations will be added soon!
                      </option>
                    )}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="mobile_no">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control value={endDate} type="date" readOnly required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="mobile_no">
                  <Form.Label>End Time</Form.Label>
                  <Form.Control value={endTime} type="time" readOnly required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstname">
                  <Form.Label>number Of Persons</Form.Label>
                  <Form.Select
                    value={persons}
                    onChange={(e) => {
                      setPersons(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select</option>
                    <option value="1">1 Person</option>
                    <option value="2">2 Person</option>
                    <option value="3">3 Person</option>
                    <option value="4">4 Person</option>
                    <option value="5">5+ Person</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstname">
                  <Form.Label>number Of Luggage</Form.Label>
                  <Form.Select
                    value={lugguage}
                    onChange={(e) => {
                      setLugguage(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select</option>
                    <option value="1">1 Luggage</option>
                    <option value="2">2 Luggage</option>
                    <option value="3">3 Luggage</option>
                    <option value="4">4 Luggage</option>
                    <option value="5">5+ Luggage</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                  className="mb-3"
                  controlId="mobile_no"
                >
                  <Form.Check
                    value={insurance}
                    checked={insurance}
                    onChange={(e) => {
                      setInsurance(!insurance);
                    }}
                  />
                  <Form.Label>Add car insurance</Form.Label>
                </Form.Group>
                <Col className="col1">
                  <div className="car__item">
                    <img src={car?.images[0]} alt="pic" className="w-100" />
                    <div className="car__item-content">
                      <h4 className="section__title">{car?.name}</h4>
                      <h6 className="rent__price">
                        <span className="car-price">
                          ${car?.price?.toFixed(2)}
                        </span>
                        <span>/day</span>
                      </h6>

                      <div className="car__item-info">
                        <div className="car-item-btm">
                          <span>
                            <AiFillCar />
                          </span>
                          <span className="span_m">{car?.model}</span>
                        </div>
                        <div className="car-item-btm">
                          <span>
                            <AiFillSetting />
                          </span>
                          <span className="span_m">{car?.automatic}</span>
                        </div>
                        <div className="car-item-btm">
                          <span>
                            <IoSpeedometerSharp />
                          </span>
                          <span className="span_m">{car?.speed}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <span className="payment-breakdown-titlep">
                    {car?.name + " Booked for " + bookedDays + " day(s) - "}
                  </span>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="payment-breakdown-amt"
                  >
                    <strong>${car?.price * bookedDays}</strong>
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <span className="payment-breakdown-titlep">Discount - </span>
                  <span className="payment-breakdown-amt">
                    <h5>
                      <strong>
                        {bookedDays > 7 ? `${car?.discount}%` : `0%`}
                      </strong>
                    </h5>
                  </span>
                </div>
                {/* <div
                  style={{ display: "flex" }}
                  className="payment-breakdown-cont"
                >
                  <span className="payment-breakdown-titlep">
                    Plus GST 15% -
                  </span>
                  <span className="payment-breakdown-amt">
                    <strong>
                      $
                      {car?.price * bookedDays +
                        0 +
                        car?.price * bookedDays * (15 / 100)}
                    </strong>
                  </span>
                </div> */}
                {/* <div
                  className="payment-breakdown-cont"
                  style={{ display: "flex", color: "green" }}
                >
                  <span>
                    <strong>Additional $100(Refundable) - </strong>
                  </span>
                  <span className="payment-breakdown-amt">
                    <h5>
                      <strong> $100</strong>
                    </h5>
                  </span>
                </div> */}
                <ToastContainer />
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={props.onHide}>
                Close
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={createloading ? true : false}
              >
                Create Booking
              </Button>
              {createloading && <LoadingBox></LoadingBox>}
            </Modal.Footer>
          </Form>
        </>
      )}
    </Modal>
  );
}
